import { PrescriptionPdfmake } from "./pages/pdfmake-configs/prescription-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import moment from "moment";

export class SharedService {
	public prescriptionPrint(mode, clinic, user, data, patientInfo, healthEst) {
		let clinicDetails = clinic;
		let treatmentList = data.treatment;
		let patientVisitData = data;
		let header = "Yes";

		var prescriptionPdfmake = new PrescriptionPdfmake();
		let data1 = prescriptionPdfmake.getPrintContent(
			header,
			user.heId,
			treatmentList,
			clinicDetails,
			user,
			patientVisitData,
			patientInfo,
			healthEst
		);

		// if (mode == "Print") {
		// 	pdfMake.createPdf(data1).print();
		// }

		if (mode == "Download") {
			pdfMake
				.createPdf(data1)
				.download(
					patientInfo.first_name + "_" + moment().format("DD-MMM-YYYY") + ".pdf"
				);
		}
	}
}
