import axios from "axios";
import Utils from "../utils/utils";

const AppointmentService = {
  cancelAppointment: function (heid, bookingId) {
    return axios.delete(
      `${Utils.PRIISM_API_ENDPOINT}/appt_bookings/cancel/${heid}/${bookingId}/`
    );
  },
};

export default AppointmentService;
