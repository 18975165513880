import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class PdfmakeConfig {
	pageSize = "A4";
	/*‘4A0’, ‘2A0’, ‘A0’, ‘A1’, ‘A2’, ‘A3’, ‘A4’, ‘A5’, ‘A6’, ‘A7’, ‘A8’, ‘A9’, ‘A10’,
    ‘B0’, ‘B1’, ‘B2’, ‘B3’, ‘B4’, ‘B5’, ‘B6’, ‘B7’, ‘B8’, ‘B9’, ‘B10’,
    ‘C0’, ‘C1’, ‘C2’, ‘C3’, ‘C4’, ‘C5’, ‘C6’, ‘C7’, ‘C8’, ‘C9’, ‘C10’,
    ‘RA0’, ‘RA1’, ‘RA2’, ‘RA3’, ‘RA4’,
    ‘SRA0’, ‘SRA1’, ‘SRA2’, ‘SRA3’, ‘SRA4’,
    ‘EXECUTIVE’, ‘FOLIO’, ‘LEGAL’, ‘LETTER’, ‘TABLOID’*/

	// by default we use portrait, you can change it to landscape if you wish
	pageOrientation = "portrait";

	//MetaData
	metaData = {
		title: "Prescription",
		author: "Summit Solutions",
		subject: "Computer generated Prescription",
	};

	// [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
	public pageMargins = [40, 110, 40, 40];
	manoshantiDharwadPageMargins = [40, 150, 40, 150];
	maheshDesaiClinicHubliPageMargins = [40, 300, 40, 250];
	basicPsychologicalAssessmentReportPageMargins = [40, 50, 40, 40];
	therapyReportPageMargins = [40, 60, 40, 40];
	summarySheetPageMargins = [40, 40, 40, 40];

	public content = [];

	public styles = {
		header: {
			fontSize: 18,
			bold: true,
			margin: [0, 0, 0, 10],
		},
		subheader: {
			fontSize: 16,
			bold: true,
			margin: [0, 10, 0, 5],
		},
		tableExample: {
			margin: [0, 5, 0, 15],
		},
		tableHeader: {
			bold: true,
			fontSize: 13,
			color: "black",
		},
		tableContent: {
			fontSize: 7,
			marginTop: 10,
		},
		footer: {
			alignment: "center",
			marginLeft: 20,
			fontSize: 7,
		},
	};

	private getHeader(headerContent) {
		return {
			text: headerContent,
			bold: true,
			alignment: "center",
			fontSize: 15,
			marginTop: 5,
			marginBottom: 5,
		};
	}

	private getHeader1(headerContent) {
		return {
			text: headerContent,
			bold: true,
			alignment: "right",
			fontSize: 15,
			marginTop: 10,
			marginBottom: 5,
		};
	}

	private getSubheader(headerContent) {
		return {
			text: headerContent,
			bold: true,
			fontSize: 12,
			italics: true,
			marginTop: 5,
			marginLeft: 15,
		};
	}

	private setWarningMsg(msg) {
		return {
			text: msg,
			bold: false,
			alignment: "center",
			fontSize: 8,
			italics: true,
			marginTop: 5,
			marginLeft: 15,
			color: "orange",
		};
	}

	private getlogo(logo) {
		return {
			image: logo,
			width: 40,
			height: 50,
			alignment: "left",
			marginTop: 5,
			marginLeft: 15,
		};
	}

	//Full Border => LRTB Left,Right,Top,Bottom
	public getBorder(border) {
		let left = border.indexOf("L") != -1 ? true : false;
		let right = border.indexOf("R") != -1 ? true : false;
		let top = border.indexOf("T") != -1 ? true : false;
		let bottom = border.indexOf("B") != -1 ? true : false;
		return [left, top, right, bottom];
	}

	// public formatDateTime(dateStr) {
	// 	return moment(dateStr).format("DD-MMM-YYYY HH:mm");
	// }

	// public formatDate(dateStr) {
	// 	return moment(dateStr).format("DD-MMM-YYYY");
	// }

	public getPageMargins(value) {
		let top = 0,
			bottom = 0;
		return [40, top > 0 ? top : 60, 40, bottom > 0 ? bottom : 40];
	}

	public joinTwoValues(value1, value2, delimeter) {
		let result = value1;
		if (value2) result += delimeter + value2;
		return result;
	}

	public getCommaSeparatedData(list, otherDetails = null, configs = []) {
		let result = "";
		list?.forEach(function (item, index) {
			if (item) {
				if (index > 0 && result.length > 0) result += ", ";
				if (
					item == "Other delusions" ||
					item == "Other Difficulties" ||
					item == "Other Hallucinations"
				)
					result += otherDetails ? otherDetails : "";
				else result += item === true ? configs[index] : item;
			}
		});
		return result;
	}

	public getCommaSeparatedDataFromObj(list, fieldName) {
		let result = "";
		list.forEach(function (item, index) {
			if (index > 0) result += ", ";
			result += item[fieldName];
		});
		return result;
	}

	// levelOne (field names) configuration for css
	// Label: value(description);
	public levelOneFontSize = 10;
	public levelOneLabelBold = true;
	public levelOneLabelItalics = "italics";
	public levelOneFieldBold = false;
	public levelOneAlignment = "left";
	public levelOneMargin = [45, 0, 0, 0];

	public getLevelOneCSSAtributes(inputType = null) {
		return {
			fontSize: this.levelOneFontSize,
			bold:
				inputType === "Label" ? this.levelOneLabelBold : this.levelOneFieldBold,
			alignment: this.levelOneAlignment,
			margin: !inputType ? this.levelOneMargin : [0, 0, 0, 0],
			italics: inputType === "Label" ? this.levelOneLabelItalics : "",
		};
	}

	// leveltwo configuration for css
	// Value(label): multiline description
	public levelTwoFontSize = 10;
	public levelTwoLabelBold = true;
	public levelTwoFieldBold = false;
	public levelTwoAlignment = "left";
	public levelTwoMargin = [30, 0, 0, 0];

	public getLevelTwoCSSAtributes(inputType = null) {
		return {
			fontSize: this.levelTwoFontSize,
			bold:
				inputType === "Label" ? this.levelTwoLabelBold : this.levelTwoFieldBold,
			alignment: this.levelTwoAlignment,
			margin: !inputType ? this.levelTwoMargin : [0, 0, 0, 0],
		};
	}
	//summarysheet
	public levelTwosummaryFontSize = 10;
	public levelTwoLabelsummaryBold = true;
	public levelTwoFieldsummaryBold = false;
	public levelTwosummaryAlignment = "left";
	public levelTwosummaryMargin = [15, 0, 0, 0];

	public getLevelTwoCSSAtributessummary(inputType = null) {
		return {
			fontSize: this.levelTwosummaryFontSize,
			bold:
				inputType === "Label"
					? this.levelTwoLabelsummaryBold
					: this.levelTwoFieldsummaryBold,
			alignment: this.levelTwosummaryAlignment,
			margin: !inputType ? this.levelTwosummaryMargin : [0, 0, 0, 0],
		};
	}
	// leveltwo configuration for css
	// Value(label): multiline description
	public levelTwoNotesFontSize = 10.5;
	public levelTwoNotesLabelBold = true;
	public levelTwoNotesFieldBold = false;
	public levelTwoNotesAlignment = "left";
	public levelTwoNotesMargin = [30, 0, 0, 0];

	public getLevelTwoNotesCSSAtributes(inputType = null) {
		return {
			fontSize: this.levelTwoNotesFontSize,
			bold:
				inputType === "Label"
					? this.levelTwoNotesLabelBold
					: this.levelTwoNotesFieldBold,
			alignment: this.levelTwoNotesAlignment,
			margin: !inputType ? this.levelTwoNotesMargin : [0, 0, 0, 0],
		};
	}

	// levelThree configuration for css
	public levelThreeFontSize = 11;
	public levelThreeBold = true;
	public levelThreeAlignment = "left";
	public levelThreeMargin = [15, 0, 0, 0];

	public getLevelThreeCSSAtributes() {
		return {
			fontSize: this.levelThreeFontSize,
			bold: this.levelThreeBold,
			alignment: this.levelThreeAlignment,
			margin: this.levelThreeMargin,
		};
	}

	// levelFour (SIDE HEADING) configuration for css
	public levelFourFontSize = 11;
	public levelFourBold = true;
	public levelFourAlignment = "left";
	public levelFourDecoration = "underline";

	public getLevelFourCSSAtributes(inputType = null) {
		return {
			fontSize: this.levelFourFontSize,
			bold: this.levelFourBold,
			alignment: this.levelFourAlignment,
			decoration: this.levelFourDecoration,
		};
	}

	// levelFour (TOP HEADING) configuration for css
	public levelFiveFontSize = 13;
	public levelFiveBold = true;
	public levelFiveAlignment = "center";
	public levelFiveDecoration = "underline";
	public levelFiveMargin = [0, 0, 8, 8];

	public getLevelFiveCSSAtributes() {
		return {
			fontSize: this.levelFiveFontSize,
			bold: this.levelFiveBold,
			alignment: this.levelFiveAlignment,
			decoration: this.levelFiveDecoration,
			margin: this.levelThreeMargin,
		};
	}
}
