import React, { useState, useContext, useEffect } from "react";
import { useParams } from 'react-router';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { UserContext } from "../../components/layout/Layout";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { Alert, Autocomplete, Card, CardContent, Collapse } from "@mui/material";
import Spinner from "../../components/spinner/spinner";
import Utils from "../../utils/utils";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import _ from 'lodash';

const theme = createTheme();

export default function SignIn({ onLoginSuccess }) {
	const params = useParams()
	const [patientDetails, setpatientDetails] = useState(useContext(UserContext) || {});
	const [isLoading, setIsLoading] = useState(false);
	const [referenceCode,] = useState(localStorage.getItem("referenceCode") || null);
	const [loginOrRegisterObj, setLoginOrRegisterObj] = useState({});
	const [, setLoginErrorMessage] = useState("");
	const [validations, setValidations] = useState({});
	const [status, setStatus] = useState("");
	const [countriesList, setCountriesList] = useState([]);
	const [mobileLength, setMobileLength] = useState(10);

	useEffect(() => {
		setLoginOrRegisterObj({ "country_code": { "name": "India", "code": "IN", "countryCode": "+91", "mobile_length": "10" } });
		getCountries();
	}, [])

	const getCountries = () => {
		axios.get(`${Utils.PRIISM_API_ENDPOINT}/appt_bookings/get-countries/`)
			.then((res) => {
				setCountriesList(res?.data);
			});
	}


	// handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
	// 	this.setState({ isLoading: true });
	// 	const md5 = require("md5");
	// 	event.preventDefault();
	// 	this.setState({
	// 		isEmailValid: true,
	// 		isPasswordValid: true,
	// 		message: "",
	// 	});
	// 	const data = new FormData(event.currentTarget);
	// 	if (data.get("email") === "") {
	// 		this.setState({ isEmailValid: false });
	// 	} else if (data.get("password") === "") {
	// 		this.setState({ isPasswordValid: false });
	// 	} else {
	// 		this.setState({ isEmailValid: true, isPasswordValid: true });
	// 	}
	// 	if (this.state.isEmailValid && this.state.isPasswordValid) {
	// 		axios
	// 			.post(`${Utils.PRIISM_APPT_API_ENDPOINT}/login`, {
	// 				email: data.get("email"),
	// 				password: md5(data.get("password")),
	// 				mode: "patient",
	// 			})
	// 			.then((res) => {
	// 				this.setState({ isLoading: false });
	// 				this.props.onLoginSuccess(res.data);
	// 			})
	// 			.catch((error) => {
	// 				let message = "Error in sign in. Please try again later.";
	// 				if (
	// 					error &&
	// 					error.response &&
	// 					error.response.data &&
	// 					error.response.data.message
	// 				) {
	// 					message = error.response.data.message;
	// 				}
	// 				this.setState({ message: message, isLoading: false });
	// 				console.error(error);
	// 			});
	// 	}
	// };

	const gotoCreateAppointment = () => {
		window.location.href = "/home/" + referenceCode;
	}

	const requestOTP = (send_via = "SMS", purpose = "LOGIN") => {
		// if (!loginOrRegisterObj?.email && !loginOrRegisterObj?.mobile) {
		// 	alert("Please Enter Email or Mobile No to send OTP.");
		// 	return false;
		// }
		let payload = {
			"email": loginOrRegisterObj?.email ?? '',
			"country_code": loginOrRegisterObj?.country_code ? (loginOrRegisterObj?.country_code?.countryCode) : "",
			"mobile": loginOrRegisterObj?.mobile ?? '',
			"send_otp_through": send_via,  //"EMAIL_AND_SMS", "SMS", "EMAIL" 
			"status": "REQUESTED",
			"purpose": purpose
		}
		setIsLoading(true);
		axios.post(`${Utils.PRIISM_APPT_API_ENDPOINT}/generate-and-send-otp`, payload)
			.then((res) => {
				setIsLoading(false);
				if (res.data.statusCode == 200 && (!(_.has(res.data, 'hasAccount')) || res.data?.hasAccount != false))
					setStatus("REQUESTED");
				if (res.data?.hasAccount) {
					setHaveAccount(res.data.hasAccount);
					setLoginOrRegisterObj({
						'mobile': loginOrRegisterObj?.mobile ?? null,
						'email': loginOrRegisterObj?.email ?? null
					});
				} else if (_.has(res.data, 'hasAccount')) {
					setLoginErrorMessage(res.data?.message);
				}
			}).catch((error) => {
				setIsLoading(false);
			});
	}

	const resendOtp = (send_via = "SMS", purpose = "LOGIN") => {
		requestOTP(send_via, purpose);
	}

	const renderTime = ({ remainingTime }) => {
		if (remainingTime === 0) {
			setStatus('TIMED_OUT');
			setLoginOrRegisterObj({ ...loginOrRegisterObj, otp: "" });
			alert("OTP has timed out, please click on 'Request New OTP' button");
		}
		return (
			<div className="timer">
				<div className="value">{remainingTime}</div>
			</div>
		);
	};

	const gotoSignup = () => {
		alert('Please select consultant');
		window.location.href = "/home/" + referenceCode;
	}

	const validateOTP = (send_via = "SMS", purpose = "LOGIN") => {
		// if (!loginOrRegisterObj?.email && !loginOrRegisterObj?.mobile) {
		// 	alert("Please Enter Email or Mobile No to send OTP.");
		// 	return false;
		// }
		let payload = {
			"email": loginOrRegisterObj.email ?? '',
			"mobile": loginOrRegisterObj.mobile ?? '',
			"otp": loginOrRegisterObj.otp ?? '',
			"status": status,
			"purpose": purpose,
			"send_otp_through": send_via
		}
		setIsLoading(true);
		axios.post(`${Utils.PRIISM_APPT_API_ENDPOINT}/validate-otp`, payload)
			.then((res) => {
				setIsLoading(false);
				setStatus(res.data.status);
				if (res.data.statusCode == 200) {
					if (res.data.status == 'VERIFIED') {
						// login and redirect to appointemnt list page
						login();
					}
				}
			}).catch((error) => {
				setIsLoading(false);
				// if (res.data.statusCode)
				//     setStatus(res.status);
			});
	}

	const login = (message = '') => {
		setIsLoading(true);
		axios.post(`${Utils.PRIISM_APPT_API_ENDPOINT}/login-with-mobileno-or-email`, {
			email: loginOrRegisterObj?.email ?? '',
			mobile: loginOrRegisterObj?.mobile ?? '',
			mode: "patient"
		}).then((res) => {
			setIsLoading(false);
			localStorage.setItem("patientDetails", JSON.stringify(res.data));
			setpatientDetails(res.data)
			onLoginSuccess(res.data)
			message = message + "  User Authenticated Successfully";
			setLoginErrorMessage(message);
			window.location.href = "/appointment-list/" + referenceCode;
		}).catch((error) => {
			setIsLoading(false);
			message = "Error in sign in. Please try again later.";
			if (
				error &&
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				message = error.response.data.message;
			}
			setLoginErrorMessage(message);
		});
	}

	const checkRequestOTPbtn = () => {
		if (!loginOrRegisterObj?.mobile && !loginOrRegisterObj?.email)
			return true;
		if ((loginOrRegisterObj?.mobile && _.has(validations, 'mobile') && !validations?.mobile))
			return true;
		if ((loginOrRegisterObj?.email && _.has(validations, 'email') && !validations?.email))
			return true;

		return false;
	}

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Container component="main" maxWidth="xs" >
					{isLoading && <Spinner />}
					<CssBaseline />
					<Card sx={{ marginTop: 2 }}>
						<CardContent>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
									<LockOutlinedIcon />
								</Avatar>
								<Typography component="h1" variant="h5">
									Sign in
								</Typography>
								<Grid container>
									<Grid item xs={12}>
										<Grid container spacing={4}>
											<Grid item xs={6} sm={6} md={6}>
												<Autocomplete
													value={loginOrRegisterObj?.country_code ? loginOrRegisterObj.country_code : ""}
													onChange={(event, newValue) => {
														console.log(newValue, loginOrRegisterObj?.country_code)
														if (newValue)
															setMobileLength(parseInt(newValue?.mobile_length) - 1);
														else
															setMobileLength(null);
														setLoginOrRegisterObj({
															...loginOrRegisterObj, 'country_code': newValue,
															'mobile': null
														});
														console.log(newValue, loginOrRegisterObj?.country_code)

													}}
													id="free-solo-with-text-demo"
													options={countriesList}
													getOptionLabel={(option) => {
														let name = "";
														// Value selected with enter, right from the input
														if (typeof option === "string") {
															name = option;
														} else {
															name = option.name + " (" + option.countryCode + ")";
														}
														return name;
													}}
													// disableClearable
													renderInput={(params) => (
														<TextField
															{...params}
															margin="normal"
															label="Country"
															variant={"outlined"}
															color="secondary"
															inputProps={{
																...params.inputProps,
															}}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={6} sm={6} md={6}>
												<TextField
													autoFocus
													margin="normal"
													// fullWidth
													helperText={
														loginOrRegisterObj?.country_code ?
															(_.has(validations, "mobile") && !validations?.mobile)
																? "Invalid Mobile Number.  Please input " + (mobileLength + 1) + " digit mobile number without country code"
																: ((!_.has(validations, "mobile") && !validations?.mobile) || (!loginOrRegisterObj?.mobile)) ? "Please input " + (mobileLength + 1) + " digit mobile number without country code" : '' : ''
													}
													error={
														_.has(validations, "mobile") &&
															!validations?.mobile
															? true
															: false
													}
													required
													// id="outlined-error-helper-text"
													label="Mobile Number"
													// defaultValue=""
													variant="outlined"
													color="secondary"
													name='mobile'
													autoComplete="off"
													value={loginOrRegisterObj?.mobile ?? ''}
													onChange={(e) => {
														setLoginOrRegisterObj({ ...loginOrRegisterObj, [e.target.name]: e.target.value, 'email': '' });
														// Regular expression to validate a 10-digit Indian mobile number
														const mobileNumberRegex = new RegExp(`^[1-9]\\d{${mobileLength}}$`) ///^[1-9]\d{9}$/;
														setValidations({ ...validations, [e.target.name]: mobileNumberRegex.test(e.target.value) });
														setStatus('');
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Divider>
											<Chip label="or" size="small" />
										</Divider>
									</Grid>
									<Grid item xs={12}>
										<TextField
											margin="normal"
											required
											fullWidth
											id="email"
											label="Email"
											name="email"
											variant="outlined"
											color="secondary"
											autoComplete="off"
											value={loginOrRegisterObj?.email ?? ''}
											helperText={(_.has(validations, 'email') && !validations?.email) ? "Invalid Email" : ""}
											error={(_.has(validations, 'email') && !validations?.email) ? true : false}
											onChange={(e) => {
												setLoginOrRegisterObj({
													...loginOrRegisterObj, [e.target.name]: e.target.value,
													'mobile': ''
												});
												// Regular expression to validate email addresses
												const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
												setValidations({ ...validations, [e.target.name]: emailRegex.test(e.target.value) });
												setStatus('');
											}}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={2} justifyContent={'center'}>
									<Grid item xs={12} sm={12} md={12} align={'center'}>
										{!status &&
											<button
												className="create-appointment-btn"
												onClick={() => { requestOTP("EMAIL_AND_SMS", "LOGIN") }}
												disabled={checkRequestOTPbtn()}
											>
												Request OTP
											</button>
										}
										{(status === 'TIMED_OUT') &&
											<button
												className="create-appointment-btn"
												onClick={() => { resendOtp("EMAIL_AND_SMS", "LOGIN") }}
											>
												Request New OTP
											</button>
										}
									</Grid>

									<Grid item xs={12} sm={12} md={12} align={'center'}>
										{status === 'REQUESTED' &&
											<Alert severity="info">An OTP has been sent to both your registered email and the mobile number you provided.</Alert>
										}
									</Grid>

									{((status === 'REQUESTED') || (status === 'RE-REQUESTED') || (status === 'INCORRECT-OTP')) &&
										<Grid item xs={8} sm={6} md={8} lg={10}>
											<TextField
												helperText={(_.has(validations, 'otp') && !validations?.otp) ? "Invalid OTP" : ""}
												error={(_.has(validations, 'otp') && !validations?.otp) ? true : false} fullWidth required
												id="outlined-error-helper-text"
												label="OTP"
												// defaultValue=""
												variant="outlined"
												color="secondary"
												name='otp'
												autoComplete="off"
												onChange={(e) => {
													setLoginOrRegisterObj({ ...loginOrRegisterObj, [e.target.name]: e.target.value });
													// Regular expression to validate a 5-digit OTP
													const otpRegex = /^\d{5}$/;
													setValidations({ ...validations, [e.target.name]: otpRegex.test(e.target.value) });
												}}
											/>
										</Grid>
									}

									{((status === 'REQUESTED') || (status === 'RE-REQUESTED') || (status === 'INCORRECT-OTP')) &&
										<Grid item xs={4} sm={6} md={4} lg={2}>
											<CountdownCircleTimer
												isPlaying
												duration={600}
												size={50}
												colors={[
													['#004777', 0.33],
													['#F7B801', 0.33],
													['#A30000', 0.33],
												]}
												onComplete={() => [true, 1000]}
											>
												{renderTime}
											</CountdownCircleTimer>
										</Grid>
									}

									{(status === 'REQUESTED' || status === 'RE-REQUESTED' || status === 'INCORRECT-OTP') &&
										<Grid item xs={12} sm={12} md={12}>
											<Button
												fullWidth
												className="create-appointment-btn"
												onClick={() => { validateOTP('EMAIL', "LOGIN") }}
												disabled={status === 'TIMED_OUT'}
												sx={{ mt: 3, mb: 2 }}
												variant="contained"
											>
												Verify & Sign In
											</Button>
										</Grid>
									}
									{status === 'VERIFIED' &&
										<Grid item xs={12}>
											<Alert severity="info">OTP Validated Successfully</Alert>
										</Grid>
									}
									{(status === 'INCORRECT-OTP') &&
										<Grid item xs={12}>
											<Alert severity="warning" style={{ marginTop: '15px' }}>Incorrect OTP! please try again.</Alert>
										</Grid>
									}
									<Grid item xs={12} align="center">
										Don't have an account? &nbsp;
										<Link onClick={gotoSignup} href="javascript:void(0);" variant="body2">
											{"Sign Up"}
										</Link>
									</Grid>
								</Grid>
								<Button
									type="button"
									fullWidth
									variant="contained"
									color="warning"
									sx={{ mt: 3, mb: 2 }}
									onClick={gotoCreateAppointment}
								>
									Book an Appointment
								</Button>
							</Box>
						</CardContent>
					</Card>
				</Container>
			</ThemeProvider>
		</React.Fragment >
	);
}
