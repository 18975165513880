import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ArrowRightCircle, ClockHistory, Power } from "react-bootstrap-icons";
import { useIdleTimer } from "react-idle-timer";
import LogOutService from "../../services/LogOutService";

export function Session() {
  // Timeout in 60*10 seconds
  const timeout = 600000 * 10;

  // Show prompt time and logout after 60*1 seconds(1 minute)
  const promptTimeout = 60000 * 1;

  // Modal open state
  const [open, setOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 30 minutes. Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpen(true);
    setRemaining(promptTimeout);
  };

  const onIdle = () => {
    // onIdle will be called after the promptTimeout is reached.
    // In this case 30 seconds. Here you can close your prompt and
    // perform what ever idle action you want such as log out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
    setOpen(false);
    setRemaining(0);
    LogOutService.logOut();
  };

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
  });

  const handleStillHere = () => {
    setOpen(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <Container component="main">
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle
          sx={{ backgroundColor: "#1976d2", color: "white" }}
          id="responsive-dialog-title"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", mr: 2 }}>
              <ClockHistory size={25} />
            </Box>
            Session expire warning
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div" sx={{ pt: 2 }}>
            Your session will expire in {remaining} seconds. Please choose to
            stay signed in or to sign out. Otherwise, you will be logged off
            automatically.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            color="error"
            onClick={onIdle}
            endIcon={<Power />}
          >
            Logout
          </Button>
          <Button
            endIcon={<ArrowRightCircle />}
            variant="contained"
            color="success"
            autoFocus
            onClick={handleStillHere}
          >
            Stay connected
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
