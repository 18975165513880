export class Appointment {
    session_type = 'Online';
    clinic = null;
    duration = null;
    package = null;
    package_amount = null;
    consultant = null;
    appointment_date = null;
    times_of_the_day = 0;
    appointment_time = null;
    service_name = null;
    selectedService = null;
}