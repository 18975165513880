import * as React from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import {
	Alert,
	Button,
	ButtonBase,
	Card,
	CardContent,
	CardMedia,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import "./AppointmentDetails.css";
import { PersonCircle } from "react-bootstrap-icons";

type Location = {
	locId: number;
	name: string;
};
type Consultant = {
	userId: string;
	firstName: string;
	lastName: string;
	role: string;
	displayMessage: string;
};
export default class AppointmentDetails extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<FormLabel className="option-title">Mode</FormLabel>
				<Grid container spacing={1} sx={{ mt: 4, ml: 2 }}>
					<Card sx={{ width: 225, maxWidth: 225 }}>
						<ButtonBase
							sx={{ height: "100%" }}
							onClick={() => {
								this.props.setAppointmentType({
									appointmentType: "TELE",
									consentDialogIsOpen: true,
								});
							}}
						>
							<CardContent sx={{ p: 0 }}>
								<CardMedia
									className="appointment-type-img"
									component="img"
									image="/images/mode-online.png"
									alt="tele"
								/>
								<Divider />
								<Typography
									style={{
										textTransform: "none",
										fontWeight: "bold",
										backgroundColor:
											this.props.appointmentType === "TELE" ? "#fb3" : "",
									}}
									sx={{ pt: 2, pb: 2 }}
									variant="subtitle1"
									component="div"
								>
									Tele
								</Typography>
							</CardContent>
						</ButtonBase>
					</Card>
					<Card sx={{ width: 225, maxWidth: 225, ml: 5 }}>
						<ButtonBase
							sx={{ height: "100%" }}
							onClick={() => {
								this.props.setAppointmentType({
									appointmentType: "OP",
								});
							}}
						>
							<CardContent sx={{ p: 0 }}>
								<CardMedia
									className="appointment-type-img"
									component="img"
									image="/images/mode-personal-visit.png"
									alt="PERSONAL VISIT"
								/>
								<Divider />
								<Typography
									style={{
										textTransform: "none",
										fontWeight: "bold",
										backgroundColor:
											this.props.appointmentType === "OP" ? "#fb3" : "",
									}}
									sx={{ pt: 2, pb: 2 }}
									variant="subtitle1"
									component="div"
								>
									Personal visit
								</Typography>
							</CardContent>
						</ButtonBase>
					</Card>
					{this.props.appointmentType == "OP" &&
						this.props.locations &&
						this.props.locations.length > 1 && (
							<Grid item lg={12} sx={{ ml: "-10px" }}>
								<Grid item lg={6}>
									<FormControl sx={{ width: "100%", mt: 3 }}>
										<FormLabel sx={{ ml: "-16px" }} className="option-title">
											Centre
										</FormLabel>
										<RadioGroup
											aria-labelledby="demo-radio-buttons-group-label"
											name="centre"
											value={this.props.centre}
											onChange={(event) => {
												this.props.setCity(event.target.value);
											}}
										>
											{this.props.locations?.map(
												({ locId, name }: Location, index: number) => (
													<FormControlLabel
														value={locId}
														control={<Radio />}
														label={name}
													/>
												)
											)}
										</RadioGroup>
									</FormControl>
								</Grid>
							</Grid>
						)}
					{(this.props.centre != "" || this.props.consentIsAgreed) && (
						<Grid item lg={12} sx={{ marginLeft: "-10px" }}>
							<Grid item lg={6}>
								<FormControl sx={{ width: "100%", mt: 3 }}>
									<FormLabel
										sx={{ ml: "-16px", mb: 2 }}
										className="option-title"
									>
										Consultant or counselor
									</FormLabel>
									<RadioGroup
										name="Consultant or Counselor"
										value={this.props.consultant}
										onChange={(event) => {
											this.props.setConsultant(event.target.value);
										}}
									>
										{this.props.consultants?.map(
											(
												{
													userId,
													firstName,
													lastName,
													role,
													displayMessage,
												}: Consultant,
												index: number
											) => (
												<FormControlLabel
													value={userId}
													control={<Radio />}
													label={
														<div style={{ padding: 2 }}>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<div
																	style={{
																		marginRight: 24,
																		flexDirection: "column",
																	}}
																>
																	<span
																		style={{ marginRight: 2, float: "left" }}
																	>
																		{firstName}
																	</span>
																	{lastName && (
																		<span style={{ marginRight: 2 }}>
																			{lastName}
																		</span>
																	)}
																	<div className="doctor-role">{role} </div>
																</div>
																<PersonCircle color="#1976d2" size={30} />
																{displayMessage && (
																	<Alert
																		severity="info"
																		sx={{ backgroundColor: "white" }}
																	>
																		{displayMessage}
																	</Alert>
																)}
															</div>
														</div>
													}
												/>
											)
										)}
									</RadioGroup>
								</FormControl>
							</Grid>
						</Grid>
					)}
					{(this.props.centre != "" || this.props.consentIsAgreed) && (
						<Container sx={{ mt: 4, padding: "0px !important" }}>
							<FormLabel sx={{ ml: "-16px", mb: 2 }} className="option-title">
								Type
							</FormLabel>
							<Container sx={{ display: "flex", padding: "0px !important" }}>
								<Button
									sx={{ p: 1.5, borderRadius: 0 }}
									variant={
										this.props.consultationType === "FIRST"
											? "contained"
											: "outlined"
									}
									onClick={() => {
										this.props.setConsultationType("FIRST");
									}}
								>
									First consultation / Therapy session
								</Button>
								<Button
									sx={{ borderRadius: 0 }}
									variant={
										this.props.consultationType === "FOLLOWUP"
											? "contained"
											: "outlined"
									}
									onClick={() => {
										this.props.setConsultationType("FOLLOWUP");
									}}
								>
									Followup consultation
								</Button>
							</Container>
						</Container>
					)}
				</Grid>
				<Dialog
					maxWidth="lg"
					open={this.props.consentDialogIsOpen}
					onClose={() => {
						this.props.onConsentDialog(false);
					}}
					aria-labelledby="responsive-dialog-title"
				>
					<DialogTitle
						sx={{ width: "100%", backgroundColor: "#1976d2", color: "white" }}
						id="responsive-dialog-title"
					>
						{"CONSENT FOR CONSULTATION / THERAPY"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText component="span">
							<ol>
								<li>
									I understand that I am seeking consultation/psychotherapy for
									evaluation and treatment of psychological issues.
								</li>
								<li>
									Psychological issues are complex and requires a few sessions
									for a thorough understanding of the difficulties. The
									evaluation will involve clinical interview, psychological
									tests and in some cases medical examination and investigations
									may be necessary. In most cases one session is not enough to
									achieve any outcome.
								</li>
								<li>
									I understand that I need to provide all the information about
									past or current medical or psychiatric illness and any
									treatment that I may have received for the same in the past or
									which I am currently taking. I understand that I need to share
									information regarding any allergies or reactions to drugs I
									may have had.
								</li>
								<li>
									I understand that the evaluation may also require you to talk
									to my companion (relative/friend) who knows me and has
									observed me closely for a reasonable period of time.
								</li>
								<li>
									I understand that the information shared in
									consultation/therapy session will be maintained confidential
									and not shared with others without my consent. I also
									understand that in some situations, confidentiality may need
									to be breached if there is a risk of harm to self/others or
									when legally mandated (provision of the POCSO act, medico
									legal cases, court cases).
								</li>
								<li>
									I understand that I am free to ask questions and obtain
									clarifications regarding my treatment. I am free to
									voluntarily withdraw from treatment at any point of time at my
									own risk.
								</li>
								<li>
									I understand that psychotherapy requires that I discuss my
									problems and difficulties with a psychotherapist, who will
									endeavour to provide a supportive, empathic environment and
									listen attentively. The psychotherapist may pay particular
									attention to my feelings, thought patterns, and ways of
									interacting in the world and may point these out to me so that
									I may gain increased understanding and awareness of how events
									in my life are impacting on me. The purpose of psychotherapy
									is not to offer advice or solutions to the problems that I am
									confronting, but to assist me to come to the best decisions
									and solutions for my particular situation.
								</li>
								<li>
									All clients are seen by appointment. If I am unable to keep an
									appointment I understand I need to reschedule 24 hours in
									advance. I understand that in case I have missed the
									appointment without rescheduling, the charges paid cannot be
									refunded or adjusted for the next session and I will need to
									book a fresh appointment making the payment again.
								</li>
								<li>
									Online sessions are conducted as per the telemedicine
									guidelines 2020 issued by the MOHFW, Govt. of India.
								</li>
								<li>
									Online consultations/therapy though it is a convenient way of
									seeking medical support, it does not substitute face-to-face
									support in its entirety.
								</li>
								<li>
									I understand that online consultation cannot be used for the
									purpose of medical/psychiatric emergencies. In case of any
									medical / psychiatric emergency, I will have to visit a
									psychiatrist or physician available near my place. Under no
									circumstances, emergency-based online consultation shall be
									entertained.
								</li>
								<li>
									I understand that it is up to the discretion of the
									doctor/psychologist to ascertain whether online
									consultation/therapy can be done. If they feel that for any
									reason online consultation/therapy is not preferred in my case
									they may terminate the session after informing me the reason
									and ask me to seek an in person consultation.
								</li>
								<li>
									I understand that no medical certificates can be provided
									following online consultations. I will need to visit a doctor
									in person for evaluation and certification.
								</li>
								<li>
									It is my responsibility to ensure that I have good internet
									connectivity during the session. The
									therapist/doctor/technical platform are not responsible for
									failure of sessions due to technical difficulties at my end. I
									also understand that a replacement appointment will not be
									provided for such failed sessions. However, if the technical
									failure is at the end of the doctor/therapist a replacement
									session will be arranged.
								</li>
								<li>
									I understand that if medications are prescribed by the doctor
									after the consultation, an e-copy of the prescription will be
									e-mailed to me and for any clarification on the prescription I
									may contact the clinic number of the doctor during working
									hours. I am advised to stop the medications and consult a
									nearby doctor in-person immediately in case of any intolerance
									pertaining to prescription and side effect of medications.
								</li>
								<li>
									Upon booking the appointment, I will receive an email with
									link for the video consultation/therapy on the email id I have
									registered on the portal. I understand that the appointment is
									not transferable and the link should not be shared with
									anybody and should be used only by the person in whose name
									the appointment has been booked.
								</li>
								<li>
									The online sessions may be recorded by the doctor/therapist
									for the purpose of documentation. I understand that under no
									circumstance I am allowed to record the audio or video of the
									sessions without the consent of the doctor/therapist.
								</li>
								<li>
									I agree to be contacted by the clinic by telephone or email to
									communicate information regarding my appointments or any
									information relevant to my treatment.
								</li>
								<li>
									I agree that I shall not contact my doctor/therapist on
									personal numbers, social media platforms and outside working
									hours.
								</li>
								<li>
									I also agree that it is a possibility that after the first
									couple of meetings, my therapist will assess if they can help
									me. If at any point during the therapy, my therapist assesses
									that they are ineffective in helping me to reach the
									therapeutic goals or perceive me as non-compliant or
									non-responsive, they will discuss with me about the
									termination of treatment. In such a case, if appropriate
									and/or necessary, they may give a referral that may be of help
									to me.
								</li>
							</ol>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							variant="contained"
							color="error"
							onClick={() => {
								this.props.setAppointmentType({
									appointmentType: "",
								});
								this.props.onConsentDialog(false);
							}}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							autoFocus
							onClick={() => {
								this.props.onConsentAgreed(true);
								this.props.onConsentDialog(false);
							}}
						>
							Agree
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}
