import * as React from "react";
import axios from "axios";
import Utils from "../../utils/utils";
import { useParams } from 'react-router-dom';
import './Profile.css';
import { svgImages } from './Svg';
import { Grid, Paper } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import _ from "lodash";

interface ClassComponentProps {
    [key: string]: string; // Index signature for string properties
}

interface ProfileState {
    showMore: boolean;
    isLoading: boolean;
    referenceCode: string;
    doctor: any;
    doctorId: string;
}

class Profile extends React.Component<ClassComponentProps, ProfileState> {
    constructor(props: ClassComponentProps) {
        super(props);
        this.state = {
            showMore: false,
            isLoading: false,
            referenceCode: this.props.heCode,
            doctor: {},
            doctorId: this.props.userId
        };
    }

    getConsultantById(requestHeader) {
        this.setState({ ...this.state, 'isLoading': true });
        axios.get(
            `${Utils.PRIISM_API_ENDPOINT}/appt_bookings/get-doctor/${this.state['doctorId']}/`, requestHeader
        ).then((res) => {
            let consultant = res.data || {};
            this.setState({ ...this.state, "doctor": consultant, 'isLoading': false });
        });
    }

    componentDidMount(): void {
        axios.get(
            `${Utils.PRIISM_APPT_API_ENDPOINT}/get/api-request-header`
        ).then((res) => {
            let requestHeader = res.data || null;
            if (requestHeader)
                this.getConsultantById(requestHeader);
        });
    }

    componentWillUnmount(): void {
    }


    toggleShowMore = () => {
        this.setState((prevState) => ({ showMore: !prevState.showMore }));
    };

    gotoBookAppointmentPage = () => {
        if (this.state.doctor?.userId)
            window.location.href = "/book-an-appointment/" + this.state.referenceCode + "/" + this.state.doctor?.userId;

    }

    render() {
        const { showMore, isLoading, doctor } = this.state;
        const aboutMeText = doctor.aboutMe || ''; // Replace 'aboutMe' with the actual property in your data
        return (
            <React.Fragment>
                <div className="profile-container" >
                    <React.Fragment>
                        <div className="profile-header">
                            <img
                                className="view-profile-image"
                                src={doctor?.profilePicture ? (Utils.PRIISM_WEB_URL + doctor.profilePicture) : "/images/default_user.png"}
                                alt="Profile"
                            />
                            <div className="profile-details">
                                <h2>{doctor?.firstName}{(doctor?.lastName) ? ' ' + doctor?.lastName : ''}</h2>
                                {doctor['title'] && <div style={{ fontWeight: 'bold' }}>{doctor['title']}</div>}
                                {doctor['qualification'] && <div>{doctor['qualification']}</div>}
                                {doctor['experienceInYears'] && <div>
                                    <strong>{doctor['experienceInYears']}+ </strong>years of experience
                                </div>}
                                <div>Session Starts @&nbsp;
                                    <b>
                                        <span className="fee">
                                            &#x20B9;
                                            {doctor?.['billAmtStartsAt']?.['amount']} for {doctor?.['billAmtStartsAt']?.['duration']} mins
                                        </span>
                                    </b>
                                </div>
                            </div>
                            <div>
                                <div className="book-appointment">
                                    <button className="book-button" onClick={() => { this.gotoBookAppointmentPage() }}>Book an Appointment</button>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </React.Fragment>

                    {aboutMeText &&
                        <React.Fragment>
                            <div className="profile-section" style={{
                                paddingBottom: '30px',
                                // backgroundImage: 'url("/images/bg_about_me.jpeg")',
                                // backgroundRepeat: 'no-repeat',
                                // backgroundSize: 'cover'
                            }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} sm={2} md={2} style={{ textAlign: 'center' }}>
                                        <img src={svgImages.doctor} alt="About me" style={{ maxHeight: 'fit-content' }} />
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={10}>
                                        <div className={`col-11 about-me-content ${showMore ? 'expanded' : ''}`}>
                                            <p className="about-me">
                                                <div dangerouslySetInnerHTML={{ __html: aboutMeText }} /></p>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div>
                                    {aboutMeText && aboutMeText.split(/\b\w+\b/g).filter(Boolean).length > 120 &&
                                        <span className="read-more-link" onClick={this.toggleShowMore}>
                                            {showMore ? 'Show Less' : 'Read More'}
                                        </span>
                                    }
                                </div>
                            </div><hr />
                        </React.Fragment>
                    }

                    {doctor?.['expertise'] && doctor['expertise'].length > 0 &&
                        <React.Fragment>
                            <div className="psychiatry-languages-img">
                                <img src={svgImages?.['consultation']} alt="I offer consultation for" height={60} />
                                <span className="psychiatry-languages-text">&nbsp;&nbsp;&nbsp; I offer consultation for</span>
                            </div>
                            <div className="psychiatry-offer-container">
                                <div className="psychiatry-categories">
                                    <Grid container spacing={2}>
                                        {doctor['expertise'].map(expertise => (
                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                <Chip
                                                    avatar={<Avatar alt="Natacha" src={svgImages?.[expertise]} />}
                                                    label={expertise}
                                                    variant="outlined"
                                                    color="primary"
                                                />
                                            </Grid>
                                        ))
                                        }
                                    </Grid>
                                </div>
                            </div>

                            {/* <div className="psychiatry-offer-container">
                                <div className="psychiatry-categories">
                                    {doctor['expertise'].map(expertise => (
                                        <div className="psychiatry-category">
                                            <div className="psychiatry-category-img">
                                                <img src={svgImages?.[expertise]} alt={expertise} height={173} />
                                            </div>
                                            <span className="psychiatry-category-text">{expertise}</span>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div> */}
                            <hr />
                        </React.Fragment>
                    }

                    {doctor?.['languagesISpeak'] && doctor['languagesISpeak'].length > 0 &&
                        <React.Fragment>
                            <div className="psychiatry-languages-img">
                                <img src={svgImages?.['languagesISpeak']} alt="Languages I speak" height={60} />
                                <span className="psychiatry-languages-text">&nbsp;&nbsp;&nbsp; Languages I speak</span>
                            </div>
                            <div style={{ backgroundColor: '#4004A417', padding: '20px', borderRadius: '10px' }}>
                                <Grid container spacing={2}>
                                    {doctor['languagesISpeak'].map(language => (
                                        <Grid item xs={6} sm={4} md={3} lg={2}>
                                            <Chip avatar={<Avatar>{language.substring(0, 1)}</Avatar>}
                                                variant="outlined" label={language} color="primary" />
                                        </Grid>
                                    ))
                                    }
                                </Grid>
                            </div>
                            {/* <Grid container spacing={2}>
                                {doctor['languagesISpeak'].map(language => (
                                    <Grid item >
                                        <div className="language">
                                            <span>{language}</span>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid> */}
                            <hr />
                        </React.Fragment>
                    }

                    {doctor?.['myAffiliations'] &&
                        <React.Fragment>
                            <div className="psychiatry-languages-img">
                                <img src={svgImages?.['rewards']} alt="My Affiliations" height={60} />
                                <span className="psychiatry-languages-text">&nbsp;&nbsp;&nbsp; My Affiliations</span>
                            </div>
                            <div className="psychiatry-categories">
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <div dangerouslySetInnerHTML={{ __html: doctor['myAffiliations'] }} />
                                    </Grid>
                                </Grid>
                            </div>
                        </React.Fragment>
                    }

                    <div className="book-appointment">
                        <button className="book-button" onClick={() => { this.gotoBookAppointmentPage() }}>Book an Appointment</button>
                    </div>

                </div>
            </React.Fragment >
        );
    }
}
// Functional component to extract URL params
const ParamExtractor = () => {
    const params = useParams<ClassComponentProps>();

    // Pass the extracted params to the class component
    return <Profile {...params} />;
};

export default ParamExtractor;
