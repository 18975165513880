import { BallTriangle } from "react-loader-spinner";
import LoadingOverlay from "react-loading-overlay-ts";
import "./spinner.css";

export default function Spinner() {
  return (
    <LoadingOverlay
      active={true}
      spinner={<BallTriangle color="#00BFFF" height={80} width={80} />}
    ></LoadingOverlay>
  );
}
