import React, { useState } from "react";
import Utils from "../../utils/utils";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
	Alert,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	FormControl,
	FormLabel, Collapse,
	Grid,
	Radio,
	RadioGroup,
} from "@mui/material";
import axios from "axios";
import Spinner from "../../components/spinner/spinner";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import CssBaseline from "@mui/material/CssBaseline";
import _ from "lodash";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastConfigs } from "../../utils/SharedHelpers";

const theme = createTheme();

export default function SignUp() {
	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState({});
	const [referenceCode] = useState(localStorage.getItem("referenceCode") || null);
	const [status, setStatus] = useState("");
	const [validations, setValidations] = useState({});
	const [errorMessage, setErrorMessage] = useState("");



	const requestOTP = (send_via = "SMS", purpose = "LOGIN") => {
		let payload = {
			"email": user?.email ?? '',
			"mobile": user?.mobile ?? '',
			"send_otp_through": send_via,  //"EMAIL_AND_SMS", "SMS", "EMAIL" 
			"status": "REQUESTED",
			"purpose": purpose
		}
		setIsLoading(true);
		axios.post(`${Utils.PRIISM_APPT_API_ENDPOINT}/generate-and-send-otp`, payload)
			.then((res) => {
				setIsLoading(false);
				if (res.data.statusCode == 200 && (!(_.has(res.data, 'hasAccount')) || res.data?.hasAccount != false))
					setStatus("REQUESTED");
			}).catch((error) => {
				setIsLoading(false);
			});
	}

	const resendOtp = (send_via = "SMS", purpose = "LOGIN") => {
		requestOTP(send_via, purpose);
	}

	const renderTime = ({ remainingTime }) => {
		if (remainingTime === 0) {
			setStatus('TIMED_OUT');
			setLoginOrRegisterObj({ ...loginOrRegisterObj, otp: "" });
			alert("OTP has timed out, please click on 'Request New OTP' button");
		}
		return (
			<div className="timer">
				<div className="value">{remainingTime}</div>
			</div>
		);
	};

	const validateOTP = (send_via = "SMS", purpose = "LOGIN") => {
		let payload = {
			"status": status,
			"purpose": purpose,
			"send_otp_through": send_via,
			...user
		}
		setIsLoading(true);
		axios.post(`${Utils.PRIISM_APPT_API_ENDPOINT}/validate-otp`, payload)
			.then((res) => {
				setIsLoading(false);
				setStatus(res.data.status);
				if (res.data.statusCode == 200) {
					if (res.data.status == 'VERIFIED') {
						// login and redirect to appointemnt list page
						createPatientRecordAndLogin();
						if (res.data.statusCode != 200) {
							setErrorMessage(res.data.message);
							toast.error(res.data.message, toastConfigs);
						}
					}
				}
			}).catch((error) => {
				console.log(error)
				setIsLoading(false);
			});
	}
	const createPatientRecordAndLogin = () => {
		setIsLoading(true);
		if (_.has(user, "otp"))
			delete user["otp"];
		axios.post(`${Utils.PRIISM_APPT_API_ENDPOINT}/signup-with-mobileno`, user)
			.then((res) => {
				setIsLoading(false);
				// setErrorMessage("User Account Created Successully.");
				window.location.href = "/sign-in/" + referenceCode;
			}).catch((error) => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message
				) {
					let message = error.response.data.message;
					setErrorMessage(message);
					toast.error(message, toastConfigs);
				}
				setIsLoading(false);
			});
	}

	const checkRequestOTPbtn = () => {
		const fields = ['first_name', 'age', 'sex', 'mobile', 'email'];
		for (let i = 0; i < fields.length; i++) {
			if (!user?.[fields[i]])
				return true;
			if (!validations?.[fields[i]])
				return true;
		}
		return false;
	}

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="sm" >
				{isLoading && <Spinner />}
				<CssBaseline />
				<Card sx={{ marginTop: 2 }}>
					<CardHeader
						avatar={
							<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
								<LockOutlinedIcon />
							</Avatar>
						}
						// action={
						// 	<IconButton aria-label="settings">
						// 		<MoreVertIcon />
						// 	</IconButton>
						// }
						title={
							<Typography component="h1" variant="h5">
								Sign up
							</Typography>
						}
					// subheader="September 14, 2016"
					/>
					<CardContent>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<Grid container justifyContent={'center'} spacing={2}>
								<Grid item xs={12} sm={12}>
									<TextField fullWidth required
										id="outlined-error-helper-text"
										label="Name"
										// defaultValue=""
										helperText={(_.has(validations, 'first_name') && !validations?.first_name) ? "Invalid Name" : ""}
										error={(_.has(validations, 'first_name') && !validations?.first_name) ? true : false}
										onChange={(e) => {
											setUser({ ...user, [e.target.name]: e.target.value })
											// Regular expression to validate names with alphabets and spaces
											const nameRegex = /^[a-zA-Z\s]{3,}$/;
											setValidations({ ...validations, [e.target.name]: nameRegex.test(e.target.value) });
											setStatus('');
											setErrorMessage('');
										}}
										variant="outlined"
										color="secondary"
										name='first_name'
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={7}>
									<FormControl fullWidth>
										<FormLabel style={{ textAlign: 'left' }} id="demo-row-radio-buttons-group-label">
											Gender
										</FormLabel>
										<RadioGroup
											row
											aria-labelledby="demo-row-radio-buttons-group-label"
											name="sex"
											onChange={(e) => {
												setUser({ ...user, [e.target.name]: e.target.value });
												setValidations({ ...validations, [e.target.name]: true });
												setStatus('');
												setErrorMessage('');
											}}
											value={user?.sex}
										>
											<FormControlLabel value="F" control={<Radio />} label="Female" />
											<FormControlLabel value="M" control={<Radio />} label="Male" />
											<FormControlLabel value="O" control={<Radio />} label="Other" />
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={5}>
									<TextField fullWidth required
										id="outlined-error-helper-text"
										label="Age"
										type="number"
										// defaultValue=""
										helperText={(_.has(validations, 'age') && !validations?.age) ? "Invalid Age" : ""}
										error={(_.has(validations, 'age') && !validations?.age) ? true : false}
										onChange={(e) => {
											setUser({ ...user, [e.target.name]: e.target.value })
											// Regular expression to validate age
											const ageRegex = /^(?:1[0-4]\d|150|[1-9]\d?)$/;
											setValidations({ ...validations, [e.target.name]: ageRegex.test(e.target.value) });
											setStatus('');
											setErrorMessage('');
										}}
										variant="outlined"
										color="secondary"
										name='age'
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<TextField fullWidth
										id="outlined-error-helper-text"
										label="Email Address"
										// defaultValue=""
										// helperText="Incorrect entry."
										variant="outlined"
										color="secondary"
										name='email'
										helperText={(_.has(validations, 'email') && !validations?.email) ? "Invalid Email" : ""}
										error={(_.has(validations, 'email') && !validations?.email) ? true : false} required
										onChange={(e) => {
											setUser({ ...user, [e.target.name]: e.target.value })
											// Regular expression to validate email addresses
											const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
											setValidations({ ...validations, [e.target.name]: emailRegex.test(e.target.value) });
											setStatus('');
											setErrorMessage('');
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<TextField
										helperText={(_.has(validations, 'mobile') && !validations?.mobile) ? "Invalid Mobile Number" : ""}
										error={(_.has(validations, 'mobile') && !validations?.mobile) ? true : false} fullWidth required
										id="outlined-error-helper-text"
										label="Mobile Number"
										// defaultValue=""
										variant="outlined"
										color="secondary"
										name='mobile'
										autoComplete="off"
										onChange={(e) => {
											setUser({ ...user, [e.target.name]: e.target.value })
											// Regular expression to validate a 10-digit Indian mobile number
											const mobileNumberRegex = /^[6-9]\d{9}$/;
											setValidations({ ...validations, [e.target.name]: mobileNumberRegex.test(e.target.value) });
											setStatus('');
											setErrorMessage('');
										}}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} sm={12} md={12} align={'center'}>
								{status === 'REQUESTED' &&
									<Alert severity="info">An OTP has been sent to both your registered email and the mobile number you provided.</Alert>
								}
							</Grid>

							{((status === 'REQUESTED') || (status === 'RE-REQUESTED') || (status === 'INCORRECT-OTP')) &&
								<Grid item xs={8} sm={6} md={8} lg={10}>
									<TextField
										helperText={(_.has(validations, 'otp') && !validations?.otp) ? "Invalid OTP" : ""}
										error={(_.has(validations, 'otp') && !validations?.otp) ? true : false} fullWidth required
										id="outlined-error-helper-text"
										label="OTP"
										// defaultValue=""
										variant="outlined"
										color="secondary"
										name='otp'
										autoComplete="off"
										onChange={(e) => {
											setUser({ ...user, [e.target.name]: e.target.value });
											// Regular expression to validate a 5-digit OTP
											const otpRegex = /^\d{5}$/;
											setValidations({ ...validations, [e.target.name]: otpRegex.test(e.target.value) });
											setErrorMessage('');
										}}
									/>
								</Grid>
							}

							{((status === 'REQUESTED') || (status === 'RE-REQUESTED') || (status === 'INCORRECT-OTP')) &&
								<Grid item xs={4} sm={6} md={4} lg={2}>
									<CountdownCircleTimer
										isPlaying
										duration={600}
										size={50}
										colors={[
											['#004777', 0.33],
											['#F7B801', 0.33],
											['#A30000', 0.33],
										]}
										onComplete={() => [true, 1000]}
									>
										{renderTime}
									</CountdownCircleTimer>
								</Grid>
							}

							{(status === 'VERIFIED' && !errorMessage) &&
								<Grid item xs={12} sm={12} md={12} align={'center'}>
									<Alert severity="info">OTP Validated Successfully</Alert>
								</Grid>
							}
							{(status === 'INCORRECT-OTP') &&
								<Grid item xs={12} sm={12} md={12} align={'center'}>
									<Alert severity="warning" style={{ marginTop: '15px' }}>Incorrect OTP! please try again.</Alert>
								</Grid>
							}

							{errorMessage &&
								<Grid item xs={12} sm={12} md={12} align={'center'}>
									<div className="signup-form-footer">
										<Collapse in={(errorMessage) ? true : false} style={{ marginTop: '10px' }}>
											<Alert severity="error">{errorMessage}</Alert>
										</Collapse>
									</div>
								</Grid>
							}
						</Box>
					</CardContent>
					<CardActions>
						<Grid
							container
							alignItems={'center'}
							justifyContent={"space-between"}
						>
							<Grid item sm={7}>
								Already have an account? &nbsp;
								<Link href={"/sign-in/" + referenceCode} variant="body2">
									Sign in
								</Link>
							</Grid>
							<Grid item sm={5} align="right">
								{!status &&
									<button
										className="create-appointment-btn"
										onClick={() => { requestOTP("EMAIL_AND_SMS", "SIGN-UP") }}
										disabled={checkRequestOTPbtn()}
									>
										Request OTP
									</button>
								}
								{(status === 'TIMED_OUT') &&
									<button
										className="create-appointment-btn"
										onClick={() => { resendOtp("EMAIL_AND_SMS", "SIGN-UP") }}
									>
										Request New OTP
									</button>
								}
								{(status === 'REQUESTED' || status === 'RE-REQUESTED' || status === 'INCORRECT-OTP') &&
									<Button
										className="create-appointment-btn"
										onClick={() => { validateOTP('EMAIL', "SIGN-UP") }}
										disabled={status === 'TIMED_OUT'}
										variant="contained"
									>
										Verify & Sign Up
									</Button>
								}
							</Grid>
						</Grid>
					</CardActions>
				</Card>
			</Container>
		</ThemeProvider>
	);
}
